.navbar {
  padding: 60px 15px 60px 30px;
  position: fixed;
  width: 100%;
  height: 80px;
  z-index: 9999;
  transition: 0.32 ease-in-out;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  transition: 0.32s ease-in-out;
}

.navbar.scrolled {
  padding: 45px 30px 45px 45px;
  background-color: var(--primaryColor);
  box-shadow: 0 0 2em 0 rgba(0, 0, 0, 0.6);
}

/* ======= left branding of navbar ======= */
.brand {
  align-items: center;
}

/* ======= right side of navbar ======= */
.nav-links {
  display: flex;
  font-size: 1.12rem;
  background-color: transparent;
}

/* ======= page navigators ======= */
.page-links {
  display: flex;
  align-items: center;
}

.page-link-button {
  margin: 0 17px;
  opacity: 0.7;
  transition: all 300ms ease-in-out;
  background-color: transparent;
  font-family: "Centra", sans-serif;
  color: white;
  font-size: 18px;
  cursor: pointer;
  border: none;

  visibility: visible;
}
.page-link-button:hover {
  opacity: 1;
  transform: scale(1.02);
}

.page-link-button.active {
  opacity: 1;
  transform: scale(1.02);
}

.page-link-button.blur {
  scale: 0.97;
  opacity: 0.5;
}

/* ======= social navigators ======= */
.social-links {
  margin: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.social-link-button {
  margin: 0 3px;
  width: 44px;
  height: 44px;
  padding-left: -100px;
  background-color: rgba(217, 217, 217, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s ease-in-out;
  z-index: 1;
  position: relative;
}

.social-link-button:hover {
  color: var(--primaryColor);
}

.social-link-button::before {
  content: "";
  position: absolute;
  width: 44px;
  height: 44px;
  z-index: -1;
  background-color: var(--fontColor);
  border-radius: 50%;
  transform: scale(0);
  transition: 0.3s ease-in-out;
}

.social-link-button:hover::before {
  transform: scale(1);
}

/* ======= contact-button me ======= */
.contact-button {
  border: 2px double var(--fontColor);
  padding: 17px 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  transition: 0.3s ease-in-out;
  margin-left: 5px;
  background-color: transparent;
  font-family: "Centra", sans-serif;
  color: white;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
}

.contact-button:hover {
  color: var(--primaryColor);
}

.contact-button::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: -1;
  background-color: var(--fontColor);
  transform: scaleX(0);
  transform-origin: left;
  transition: 0.3s ease-in-out;
}

.contact-button:hover::before {
  transform: scaleX(1);
}

/* ======= menu button ======= */
.menu {
  display: none;
  background-color: transparent;
  color: var(--fontColor);
  cursor: pointer;
  border: none;
}

.hidden {
  visibility: hidden;
}

@media only screen and (max-width: 1023px) {
  .menu {
    display: block;
  }

  .navbar {
    padding: 60px 15px 60px 10px;
  }

  .navbar.scrolled {
    padding: 0px 20px 0px 15px;
    box-shadow: 0 0 2em 0 rgba(0, 0, 0, 0.5);
  }

  .nav-links {
    padding: 25px 15px;
    background-color: transparent;
    position: fixed;
    border-radius: 1rem;
    right: -60%;
    top: 85px;
    flex-direction: column;
    margin-top: 25px;
    transition: all 0.32s ease-in-out;
    border: 1px solid rgba(255, 255, 255, 0.5);
  }

  .nav-links.scrolled {
    padding: 20px 15px;
    margin-top: 0px;
  }

  .nav-links.active {
    background-color: rgba(18, 18, 18, 0.3);
    backdrop-filter: blur(10px);
    right: 1%;
  }

  .nav-links.active.scrolled {
    background-color: var(--primaryColor);
  }

  .page-links {
    flex-direction: column;
  }

  .page-link-button {
    margin: 3px;
  }

  .page-link-button-active {
    margin: 3px;
  }

  .social-links {
    margin: 10px 11px 15px 10px;
  }
}

@keyframes popIn {
  from {
    opacity: 0;
    filter: blur(5px);
    transform: translateY(-20%);
    visibility: visible;
  }
  to {
    opacity: 0.7;
    filter: blur(0);
    transform: translateY(0);
  }
}

@keyframes popInSocial {
  from {
    opacity: 0;
    filter: blur(5px);
    transform: translateY(-20%);
    visibility: visible;
  }
  to {
    opacity: 1;
    filter: blur(0);
    transform: translateY(0);
  }
}

.popIn {
  visibility: hidden;
  animation: popIn 700ms ease-in-out;
}

.popInSocial {
  visibility: hidden;
  animation: popInSocial 700ms ease-in-out;
}

.popIn.speed-0,
.popInSocial.speed-0 {
  animation-delay: 0s;
}

.popIn.speed-1,
.popInSocial.speed-1 {
  animation-delay: 0.1s;
}

.popIn.speed-2,
.popInSocial.speed-2 {
  animation-delay: 0.2s;
}

.popIn.speed-3,
.popInSocial.speed-3 {
  animation-delay: 0.3s;
}

.popIn.speed-4,
.popInSocial.speed-4 {
  animation-delay: 0.4s;
}

.popIn.speed-5,
.popInSocial.speed-5 {
  animation-delay: 0.5s;
}

.popIn.speed-6,
.popInSocial.speed-6 {
  animation-delay: 0.6s;
}

.popIn.speed-7,
.popInSocial.speed-7 {
  animation-delay: 0.7s;
}

.popIn.speed-8,
.popInSocial.speed-8 {
  animation-delay: 0.8s;
}

.popIn.speed-9,
.popInSocial.speed-9 {
  animation-delay: 0.9s;
}
