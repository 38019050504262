.home {
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 30vh;
  padding-bottom: 20vh;
}

.home-box {
  background-color: var(--secondaryColor);
  max-width: 60%;
  height: auto;
  display: flex;
  align-items: center;
  border-radius: 15px;
  border: 2px solid rgba(255, 255, 255, 0.5);
  box-shadow: 0.5em 0.25em 10.5em 0 rgba(0, 0, 0, 0.35),
    0 0.4em 1.25em 0 rgba(0, 0, 0, 0.25);
  transform: translateX(5%);
  filter: blur(5px);
  opacity: 0.4;
  visibility: hidden;
  transition: all 1000ms ease-in-out;
}

.showen {
  transform: translateX(0);
  filter: blur(0);
  opacity: 1;
  visibility: visible;
}

.home-text {
  padding: 60px;
  padding-right: 0;
  font-size: 25px;
  text-align: start;
}

.home-text-header {
  padding: 0px 0px 20px 10px;
  font-size: 50px;
  text-align: start;
}

.home-image-frame {
  min-width: 450px;
  max-width: 450px;
  height: 450px;
  border-radius: 50%;
  margin: 20px;
  overflow: hidden;
  border: 7px solid var(--quaternaryColor);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-right: -10%;
  margin-left: 7%;
}

.home-image {
  width: 100%;
  height: auto;
  transform: scale(1);
  margin-top: 20px;
  margin-left: 0;
}

@media only screen and (max-width: 1400px) {
  .home {
    padding-top: 17vh;
    align-items: center;
  }

  .home-box {
    max-width: 90%;
    flex-direction: column;
  }

  .home-text {
    font-size: medium;
    padding: 20px;
    text-align: center;
  }

  .home-text-header {
    font-size: 45px;
    padding-top: 10px;
    padding-bottom: 15px;
    text-align: center;
  }

  .home-image-frame {
    min-width: 200px;
    max-width: 200px;
    height: 200px;
    margin: 10px 0 -10% 0;
  }

  .home-image {
    transform: scale(1.2);
    margin-left: 10px;
  }
}
