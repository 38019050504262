.experience {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.experience-tree {
  background-color: var(--tertiaryColor);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 80px;
  box-shadow: 0 0 4em 0em rgba(0, 0, 0, 0.5);
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  transition: all 1s ease-in-out;
}

.experience-header {
  border: 2px solid white;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 10px 20px;
  font-size: 40px;
  background-color: var(--quaternaryColor);
  box-shadow: 0.5em 0.25em 10.5em 0 rgba(0, 0, 0, 0.35),
    0 0.4em 1.25em 0 rgba(0, 0, 0, 0.25);
}

.vertical-timeline-element-content {
  box-shadow: 0 3px 0 #ddd, 0.5em 0.25em 7.5em 0 rgba(0, 0, 0, 0.35),
    0 0.4em 1.25em 0 rgba(0, 0, 0, 0.25) !important;
  z-index: 1 !important;
}

.experience-box-icon {
  background-color: var(--primaryColor);
}

.experience-box-header {
  font-size: 24px !important;
}

@media only screen and (max-width: 1170px) {
  .experience-tree {
    padding: 48px 7px 48px 5px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    box-shadow: 0 0 2em 0em rgba(0, 0, 0, 0.5);
  }

  .vertical-timeline-element-content {
    box-shadow: 0 3px 0 #ddd, 0.5em 0.25em 1.5em 0 rgba(0, 0, 0, 0.35),
      0 0.4em 1.25em 0 rgba(0, 0, 0, 0.25) !important;
  }
}
