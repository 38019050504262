/* ======= Custom Font ======= */
@font-face {
  font-family: Centra;
  src: url("./assets/font/CentraNo2-Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: Centra;
  src: url("./assets/font/CentraNo2-Medium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: Centra;
  src: url("./assets/font/CentraNo2-Book.ttf");
  font-weight: 400;
}

/* ======= App colors ======= */
:root {
  --primaryColor: #3b5249;
  --secondaryColor: #576057;
  --tertiaryColor: #6f796f;
  --quaternaryColor: #9faf90;
  --fontColor: #fff;
}

/* ======= Default css ======= */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-image: radial-gradient(
    rgba(59, 83, 73, 0.55),
    rgba(59, 83, 73, 0.65),
    rgba(59, 83, 73, 0.65),
    rgba(59, 83, 73, 0.55)
  );
  height: 100vh;
  color: var(--fontColor);
  font-family: "Centra", sans-serif;
}

a {
  color: var(--fontColor);
  text-decoration: none;
  font-family: "Centra", sans-serif;
}

button {
  font-family: "Centra", sans-serif;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

.wave {
  background-image: url("./assets/image/wave.svg");
  aspect-ratio: 960/300;
}

.wave2 {
  background-image: url("./assets/image/wave2.svg");
  aspect-ratio: 900/300;

  max-height: 700px;
}

.spacer {
  position: absolute;
  width: 100%;

  background-size: cover;
  transform: translateY(-1px);
  overflow: hidden;
}

@media only screen and (max-width: 1170px) {
  .wave {
    aspect-ratio: 960 / 900;
  }

  .wave2 {
    aspect-ratio: 900 / 900;
  }
}

.flip {
  transform: rotate(180deg) translateY(-2px);
  z-index: -1;
}

.bottom {
  bottom: 0;
}

.error-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.error-button {
  border: 3px dashed rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  padding: 20px 30px;
  margin: 20px;
  margin-bottom: 5%;
}

.error-button:hover {
  background-color: rgba(255, 255, 255, 0.5);
}

.loading-frame {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background-color: rgba(59, 83, 73, 0.55);
  z-index: 99;
  position: absolute;
}

.loading-logo-box {
  position: absolute;
  bottom: 50px;
  width: 120px;
  aspect-ratio: 1;
  overflow: hidden;
}

.loading-logo-icon {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.loading-frame.faint {
  animation: faint 1000ms linear forwards;
}

@keyframes faint {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    display: none;
  }
}
