.contact {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--secondaryColor);
  text-align: center;
}

.contact-header {
  font-size: 40px;
  margin-top: 40px;
  z-index: 1;
}

.contact-text {
  margin-top: 5px;
  margin-bottom: 30px;
  color: rgba(255, 255, 255, 0.6);
  z-index: 1;
  font-size: 16px;
}

.contact-area {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 0 50px;
  padding: 50px 0;
  z-index: 1;
}

.contact-image {
  display: flex;
  align-items: center;
  transform: rotate(-10deg);
}

.contact-form {
  width: 500px;
  display: flex;
  flex-direction: column;
}

.contact-input-field {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  color: #fff;
  margin: 7px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: all 0.3s ease-in-out;
  font-family: "Centra", sans-serif;
}
.contact-input-field:focus {
  background: rgba(255, 255, 255, 1);
  color: #121212;
  outline: none;
}

.contact-input-field::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}
.contact-input-field:focus::placeholder {
  color: #121212;
  opacity: 0.7;
}

.contact-mail-button {
  background-color: transparent;
  border: none;
  color: rgba(255, 255, 255, 0.6);
  font-size: 16px;
  cursor: pointer;
  z-index: 10;

  transition: all 200ms ease-in-out;
}

.contact-mail-button:hover {
  scale: 1.07;
}

@media only screen and (max-width: 1170px) {
  .contact-header {
    font-size: 30px;
    margin-top: 20px;
  }

  .contact-form {
    width: 100%;
  }

  .contact-area {
    width: 100%;
    max-width: 500px;
    margin: 0 0 20px 0;
    padding: 0 40px;
  }

  .contact-image {
    display: none;
  }
}

/* CSS for the button */
.contact-form-button {
  box-shadow: 0 0 0 0.1em inset var(--primaryColor);
  --_g: linear-gradient(var(--primaryColor) 0 0) no-repeat;

  background: var(--_g) calc(var(--_p, 0%) - 100%) 0%,
    var(--_g) calc(200% - var(--_p, 0%)) 0%,
    var(--_g) calc(var(--_p, 0%) - 100%) 100%,
    var(--_g) calc(200% - var(--_p, 0%)) 100%;
  background-size: 50.5% calc(var(--_p, 0%) / 2 + 0.5%);

  font-size: 2rem;
  cursor: pointer;
  padding: 0.2em 1em;
  font-weight: 500;
  border: none;
  border-radius: 20px;
  color: var(--fontColor);
  margin: 7px 0;

  outline-offset: 0.1em;
  transition: background-size 0.4s, background-position 0s 0.4s;
}

.contact-form-button.blur {
  opacity: 0.4;
}

.contact-form-button:hover {
  --_p: 100%;
  color: #121212;
  transition: all 0.4s, background-size 0s;
}

.contact-form-button:active {
  box-shadow: 0 0 9e9Q inset #0009;
  background-color: var(--primaryColor);
  color: #fff;
}
