.skill {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.skill-header {
  border: 2px solid white;
  border-radius: 10px;
  margin: 7vh 0 20px 0;
  padding: 10px 20px;
  font-size: 40px;
  background-color: var(--quaternaryColor);
  box-shadow: 0.5em 0.25em 10.5em 0 rgba(0, 0, 0, 0.35),
    0 0.4em 1.25em 0 rgba(0, 0, 0, 0.25);
  z-index: 1;
}

.skill-fields {
  display: flex;
}

.skill-field {
  background-color: var(--secondaryColor);
  padding: 20px 30px 10px 30px;
  margin: 10px 15px;
  border-radius: 20px;
  flex-grow: 1;
  box-shadow: 0.5em 0.25em 7.5em 0 rgba(0, 0, 0, 0.35),
    0 0.4em 1.25em 0 rgba(0, 0, 0, 0.25);
  z-index: 1;
}

.skill-list {
  padding: 10px 30px;
  font-size: 20px;
  line-height: 1.5em;
}

.skill-cv {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--secondaryColor);
  padding: 25px 70px;
  margin: 10px 15px 20vh 15px;
  border-radius: 20px;
  box-shadow: 0.5em 0.25em 7.5em 0 rgba(0, 0, 0, 0.35),
    0 0.4em 1.25em 0 rgba(0, 0, 0, 0.25);
  font-size: 25px;
  flex-grow: 1;
  z-index: 0;
  width: fit-content;
  text-align: center;
  z-index: 1;
}

.skill-cv-button-box {
  overflow: visible;
}

.slideInRight {
  transform: translateX(10%);
  filter: blur(2px);
  opacity: 0.6;
  transition: all 0.8s ease-in-out;
}

.showen {
  transform: translateX(0);
  filter: blur(0);
  opacity: 1;
}

@media only screen and (max-width: 1170px) {
  .skill {
    padding: 0 10px;
  }

  .skill-fields {
    flex-direction: column;
  }

  .skill-field {
    transform: translateX(0);
    transform: translateY(-5%);
  }

  .skill-cv {
    padding: 25px 15px;
    transform: translateX(0);
    transform: translateY(-5%);
    margin-bottom: 10vh;
  }

  .showen {
    transform: translateY(0);
  }
}

/* CSS of the button */
.skill-cv-button {
  align-items: center;
  background-color: var(--quaternaryColor);
  border: 2px solid #111;
  border-radius: 8px;
  box-sizing: border-box;
  color: #111;
  cursor: pointer;
  display: flex;
  font-weight: 500;
  height: 48px;
  justify-content: center;
  line-height: 24px;
  max-width: 100%;
  padding: 0 25px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  margin-top: 15px;

  font-family: "Centra", sans-serif;
  font-size: 18px;
}

.skill-cv-button:after {
  background-color: #111;
  border-radius: 8px;
  content: "";
  display: block;
  height: 48px;
  left: 0;
  width: 100%;
  position: absolute;
  top: -2px;
  transform: translate(8px, 8px);
  transition: transform 0.2s ease-out;
  z-index: -1;
}

.skill-cv-button:hover:after {
  transform: translate(0, 0);
}

.skill-cv-button:active {
  background-color: var(--tertiaryColor);
  outline: 0;
}

.skill-cv-button:hover {
  outline: 0;
}

@media (min-width: 768px) {
  .skill-cv-button {
    padding: 0 40px;
  }
}
